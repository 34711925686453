<template>
  <div class="container-fluid about-us">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-5">
        <h3 class="text-center mb-3">{{ $t('donateTitle') }}</h3>
        <div v-html="$t('donateText')"></div>
        <hr>
        <b-form @submit.prevent="send">
          <div class="form-group">
            <label for="quantity">{{ $t('amountToDonate') }}</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text px-4"><i class="fa fa-usd"></i></span>
              </div>
              <input type="number" name="quantity" id="quantity" class="form-control" v-validate="'required|numeric'" :state="validateState('quantity')" v-model="payload.quantity">
            </div>
            <span class="error-inputs">{{ errors.first('quantity') }}</span>
          </div>
          <div class="form-group">
            <label for="cardNumber">{{ $t('cardNumber') }}</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text px-4"><i class="fa fa-credit-card"></i></span>
              </div>
              <input type="number" name="cardNumber" id="cardNumber" class="form-control" v-validate="'required|numeric'" :state="validateState('cardNumber')" v-model="payload.number">
            </div>
            <span class="error-inputs">{{ errors.first('cardNumber') }}</span>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="form-group">
                <label for="cardMonth">{{ $t('expirationDate') }}</label>
                <div class="row">
                  <div class="col-6">
                    <input type="number" name="cardMonth" id="cardMonth" class="form-control form-control-lg" placeholder="MM" v-validate="'required|numeric'" :state="validateState('cardMonth')" v-model="payload.month">
                    <span class="error-inputs">{{ errors.first('cardMonth') }}</span>
                  </div>
                  <div class="col-6">
                    <input type="number" name="cardYear" id="cardYear" class="form-control form-control-lg" placeholder="YYYY" v-validate="'required|numeric'" :state="validateState('cardYear')" v-model="payload.year">
                    <span class="error-inputs">{{ errors.first('cardYear') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label for="card_ccv">CCV</label>
                <input type="text" name="card_ccv" id="card_ccv" class="form-control form-control-lg" v-validate="'required|numeric'" :state="validateState('card_ccv')" v-model="payload.ccv" data-vv-as="ccv">
                <span class="error-inputs">{{ errors.first('card_ccv') }}</span>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-lg btn-primary btn-block">{{ $t('submit') }}</button>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      payload: {}
    }
  },
  mounted () {
    document.title = `${this.capitalize(this.$tc('donate', 2))} - ITFA`
  },
  methods: {
    send () {
      this.$validator.validate().then(result => {
        if (result) {
          const lang = this.$route.params.lang
          const console = this.$route.params.console
          const path = `${lang}/console/${console}/donations`
          this.$axios.post(path, this.payload).then(() => {
            this.$toastr.success(this.$t('donateThanks'), window.TITLE_SUCCESS)
            this.payload = {}
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
